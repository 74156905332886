import { Outlet } from 'react-router-dom'

function EventDetailsRoot() {
	return (
		<>
			<Outlet />
		</>
	)
}
export default EventDetailsRoot
