import Board from '../components/Board'
import Info from '../components/Info'

function Association() {
	return (
		<>
			<Info />
			<Board />
		</>
	)
}
export default Association
