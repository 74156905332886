import EventBanner from './EventBanner'
import Agenda from './Agenda'

import classes from './PageContent.module.css'

function PageContent() {
	return (
		<>
			<div className={classes.banner}>
				<h1>Foyer Rural de Graye-et-Charnay</h1>
				<div className={classes.title}>
					<h2>Foyer Rural de</h2>
					<h2> Graye-et-Charnay</h2>
				</div>
			</div>
			<EventBanner />
			<h2 className={classes.infos}>
				Retrouvez toute l'actualité de l'association sur notre site
			</h2>
			<Agenda />
		</>
	)
}

export default PageContent
