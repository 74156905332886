import { Outlet } from 'react-router-dom'

function NewsDetailsRoot() {
	return (
		<>
			<Outlet />
		</>
	)
}
export default NewsDetailsRoot
