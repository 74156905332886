import EventsList from '../components/events/EventsList'

function Events() {
	return (
		<>
			<EventsList />
		</>
	)
}
export default Events
