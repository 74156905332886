import NewsList from '../components/news/NewsList'

function NewsPage() {
	return (
		<>
			<h1>Actualités du Foyer Rural de Graye-et-Charnay</h1>
			<NewsList />
		</>
	)
}
export default NewsPage
