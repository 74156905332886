import NewsForm from '../components/news/NewsForm'

function EditNewsPage() {
	return (
		<>
			<NewsForm method="PATCH" />
		</>
	)
}
export default EditNewsPage
