import { Outlet } from 'react-router-dom'

function EventsRoot() {
	return (
		<>
			<Outlet />
		</>
	)
}
export default EventsRoot
